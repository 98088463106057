
/**
 * @name: 销售管理-档口调货记录
 * @author: itmobai
 * @date: 2023-08-04 17:53
 * @description： 销售管理-档口调货记录
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { callProductDtlQueryApi, callProductDtlExportApi } from "@/apis/sale/transfer"
import { gateGetGateListApi } from "@/apis/gate/info"
import type { IGate } from "@/apis/gate/info/types"
import type { ITransfer, ITransferParam } from "@/apis/sale/transfer/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class saleTransfer extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ITransfer[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ITransferParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // 价格
  totalPrice = 0
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "调货记录ID",
        prop: "callProductId",
        width: 180,
        align: "left"
      },
      {
        label: "调入档口",
        prop: "joinGateIds",
        hide: true,
        search: true,
        type: "select",
        searchMultiple: true,
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({ list: res.data, label: 'gateName', value: 'id' }),
      },
      {
        label: "调入档口",
        prop: "joinGateName",
        align: "center",
        width: 150,
        overHidden: true
      },
      {
        label: "调出档口",
        prop: "outGateId",
        hide: true,
        search: true,
        type: "select",
        searchMultiple: true,
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({ list: res.data, label: 'gateName', value: 'id' }),
      },
      {
        label: "调出档口",
        prop: "outGateName",
        align: "center",
        width: 150,
        overHidden: true
      },
      {
        label: "商品名称",
        prop: "productName",
        search: true,
        width: 150,
        align: "center",
        overHidden: true
      },
      {
        label: "调货数量",
        prop: "callProductQty",
        align: "center",
        width: 150,
        slot: true
      },
      {
        label: "调货价",
        prop: "price",
        align: "center",
        width: 150
      },
      {
        label: "调货金额(元)",
        prop: "callProductPrice",
        align: "center",
        width: 150
      },
      {
        label: "经办人(调入-调出)",
        prop: "joinHandledBy",
        align: "center",
        width: 180
      },
      {
        label: "批次号",
        prop: "batchOns",
        align: "center",
        width: 150,
        overHidden: true
      },
      {
        label: "状态",
        prop: "status",
        width: 120,
        align: "center",
        type: "select",
        search: true,
        dicData: [
          {
            label: "待接收",
            value: 1
          },
          {
            label: "已完成",
            value: 2
          }
        ]
      },
      {
        label: "调货时间",
        prop: "callTime",
        width: 150,
        align: "center",
        search: true,
        type: "daterange",
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
      }
    ]
  }

  getList () {
    this.tableLoading = true
    const query: ITransferParam = deepCopy(this.queryParam)
    if (query.callTime && query.callTime.length) {
      query.startTime = query.callTime[0]
      query.endTime = query.callTime[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.callTime
    query.joinGateIds = ((this.queryParam.joinGateIds || []) as string[]).join(',')
    query.outGateId = ((this.queryParam.outGateId || []) as string[]).join(',')
    callProductDtlQueryApi(query).then(e => {
      if (e) {
        this.totalPrice = e.productPrice
        this.tableData = e.commonPage.list
        this.tableTotal = e.commonPage.total
        this.tableLoading = false
      }
    })
  }

  exportExcel () {
    const query: ITransferParam = deepCopy(this.queryParam)
    if (query.callTime && query.callTime.length) {
      query.startTime = query.callTime[0]
      query.endTime = query.callTime[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.callTime
    query.joinGateIds = (this.queryParam.joinGateIds && this.queryParam.joinGateIds.length) ? this.queryParam.joinGateIds : []
    query.outGateId = (this.queryParam.outGateId && this.queryParam.outGateId.length) ? this.queryParam.outGateId : []
    callProductDtlExportApi(query).then(e => {
      exportFile(e, `档口调货记录.xlsx`)
    })
  }

  created () {
    this.getList()
  }
}
