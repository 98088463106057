/**
 * @name: 销售管理-档口调货记录接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 销售管理-档口调货记录接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ITransferParam, ITransferRes } from "./types";

/**
 * 档口调货记录分页查询
 * @param params
 * @returns
 */
export const callProductDtlQueryApi = (params: ITransferParam) => get<ITransferRes>("/admin/callProductDtl/query", params)
/**
 * 档口调货记录导出
 * @param data
 * @returns
 */
export const callProductDtlExportApi = (data: ITransferParam) => postJ("/admin/callProductDtl/export", data, "blob")
